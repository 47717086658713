import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectToggleConsommation, toggleConsommation } from '../../../../store/mainReducer';
import Conso from '../../../../assets/images/conso.svg';
import ItemsTitle from '../ItemsTitle';
const Consommation = () => {
  const dispatch = useAppDispatch();
  const active = useAppSelector(selectToggleConsommation);
  const handleClick = () => {
    dispatch(toggleConsommation());
  }
  return (
    <div className='row  align-items-center'>
      <div className="col-12">
        <ItemsTitle handleClick={handleClick} active={active} title='Consommation' />
      </div>
      <div className="col-12 pb-3">
        <img src={Conso} alt="" />
      </div>
    </div>
  );
}

export default Consommation;