import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCurrentRegion, selectToggleLinesAnimation, toggleLinesAnimation } from '../../../store/mainReducer';
import ProductionVolume from './ProductionVolume';
import Flux from "../../../assets/images/lignes/flux.svg";
import styled from 'styled-components';
import Eye from "../../../assets/images/eye.svg";
import EyeClose from "../../../assets/images/eye-close.svg";
import Network from './network';
import ItemsTitle from './ItemsTitle';
import Productions from '../Productions';
import TooltipTrigger from './TooltipTrigger';

const CustomDiv = styled.div`
  background-color: #21323A;
  .flux-icon {
    width: 24px;
  }
  .flux-bloc {
    padding-left: 15px;
  }
`

const Legend = () => {
  const currentRegion = useAppSelector(selectCurrentRegion);
  const dispatch = useAppDispatch();
  const active = useAppSelector(selectToggleLinesAnimation);
  const handleClick = () => {
    dispatch(toggleLinesAnimation());
  }
  return (
    <CustomDiv className="row mobile-bg">
      <div className="col-12">{currentRegion ? <Productions /> : <ProductionVolume />}</div>
      
      <div className="col-12">

        <div className="row">
          <div onClick={handleClick} style={{cursor: "pointer"}} className="col-12 ps-3">
              <div className="row pb-2">
              <div className="col-12"><ItemsTitle title="Réseau" eye={true}></ItemsTitle></div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-10 fs-6">
                        <div className="row">
                        <div className="col-1 text-start">
                            <img src={Flux} alt="Eye" className='flux-icon' />
                          </div>
                          <div className='col-11 flux-bloc'>Flux
                          <TooltipTrigger description="Sens du transit de la ligne"/>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-2 text-end" style={{paddingRight: '30px'}}>
                        <img src={active ? Eye : EyeClose} alt="Eye" />
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>


      </div>
      <div className="col-12 ps-3">
      
        <Network />
      </div>
        
        
    </CustomDiv>
  );
}

export default Legend;