import { GeoJsonLayer } from "@deck.gl/layers/typed";
import EURO from "../../data/EU_V2_WGS84.json";



const useEuropeLayer = (): Array<GeoJsonLayer> => {
  const euro = new GeoJsonLayer({
    id: `euro`,
    data: EURO as any,
    stroked: true,
    filled: true,
    pickable: true,
    getFillColor: [21, 37, 44], // #15252C
    getLineColor: [23, 106, 136], // #176A88
    lineWidthMinPixels: 1
  });
  return [euro];
};

export default useEuropeLayer;