import { Color } from '@deck.gl/core/typed';
export enum LINE_TYPES {
    'LINE_255' = 'LINE_255',
    'LINE_400' = 'LINE_400',
    'LINE_CC' = 'LINE_CC'
}
export enum LINE_LABELS {
    'LINE_255' = 'Ligne 225kV',
    'LINE_400' = 'Ligne 400kV',
    'LINE_CC' = 'Ligne CCHT'
}
export enum LINE_VOLTAGE {
    'LINE_255' = '225kV',
    'LINE_400' = '400kV',
    'LINE_HVDC' = 'CC'
}
export const LINE_COLORS: {'LIT_225': Color, 'LIT_400': Color, 'LIT_HVDC': Color} = {
    'LIT_225': [175, 0, 70], // #83b532
    'LIT_400': [255, 132, 206], // #e15989
    'LIT_HVDC' : [72, 207, 199] // #48CFC7
}

export enum LEGEND_CONSTANTS {
    'LINES_400_VISIBILITY' = 'LINES_400_VISIBILITY',
    'LINES_400_ANIMATION' = 'LINES_400_ANIMATION',
    'CONSOMMATION_VISIBILITY' = 'CONSOMMATION_VISIBILITY',
    'PRODUCTION_VISIBILITY' = 'PRODUCTION_VISIBILITY',
    'NUCLEAR_VISIBILITY' = 'NUCLEAR_VISIBILITY',
    'WIND_VISIBILITY' = 'WIND_VISIBILITY',
    'THERMIC_VISIBILITY' = 'THERMIC_VISIBILITY',
}
export const CONSOMMATION_COLORS: {'Seuil_1': Color, 'Seuil_2': Color, 'Seuil_3': Color} = {
    // 'Seuil_1': [255, 0, 0], 
    Seuil_1: [102, 66, 115], // #664273 seuil5 dans old mock

     // 'Seuil_2': [255, 255, 51],
   'Seuil_2': [63, 43, 74],
    // 'Seuil_3': [255, 51, 46],
    'Seuil_3': [35, 31, 46],
}
export enum LEGEND_LABELS {
    'LINES_400_VISIBILITY' = 'Lignes 400kV',
    'LINES_400_ANIMATION' = 'Flux',
    'CONSOMMATION_VISIBILITY' = 'Consommation',
    'PRODUCTION_VISIBILITY' = 'Production',
    'NUCLEAR_VISIBILITY' = 'Nucléaire',
    'WIND_VISIBILITY' = 'Eolien Marin',
    'THERMIC_VISIBILITY' = 'Thermique'
}

export enum PRODUCTION_UNITS_KEYS {
    NUCLEAR = "NUCLEAR",
    HYDRAULICS = "HYDRAULICS",
    THERMAL = "THERMAL",
    WIND_OFFSHORE = "WIND_OFFSHORE",
    WIND_ONSHORE = "WIND_ONSHORE",
    PHOTOVOLTAIC = "PHOTOVOLTAIC",
    BIOENERGIE = "BIOENERGIE"
}
export enum PRODUCTION_UNITS_LABELS {
    NUCLEAR = "Nucléaire",
    HYDRAULICS = "Hydraulique",
    THERMAL = "Thermique",
    WIND_OFFSHORE = "Éolien marin",
    WIND_ONSHORE = "Éolien terrestre",
    PHOTOVOLTAIC = "Photovoltaique",
    BIOENERGIE = "Bioénergie"
}

export const REGION_NUMBERS: {
    [key: string]: string;
} = {
    11: "Île-de-France",
    24: "Centre-Val de Loire",
    27: "Bourgogne-Franche-Comté",
    28: "Normandie",
    32: "Hauts-de-France",
    44: "Grand Est",
    52: "Pays de la Loire",
    53: "Bretagne",
    75: "Nouvelle-Aquitaine",
    76: "Occitanie",
    84: "Auvergne-Rhône-Alpes",
    93: "Provence-Alpes-Côte d'Azur",
    94: "Corse"
};
export const REGION_KEYS: {
    [key: string]: string;
} = {
    11: "ILE-DE-FRANCE",
    24: "CENTRE-VAL DE LOIRE",
    27: "BOURGOGNE-FRANCHE-COMTE",
    28: "NORMANDIE",
    32: "HAUTS-DE-FRANCE",
    44: "GRAND EST",
    52: "PAYS DE LA LOIRE",
    53: "BRETAGNE",
    75: "NOUVELLE-AQUITAINE",
    76: "OCCITANIE",
    84: "AUVERGNE-RHONE-ALPES",
    93: "PROVENCE-ALPES-COTE D'AZUR",
    94: "CORSE"
};