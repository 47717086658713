import React from 'react';
import { LINE_LABELS } from '../../../../utils/enums';
import styled from 'styled-components';
interface LignesFluxProps {
    type: string;
    src: string;
}

const CustomDiv = styled.div`
    .ligne-icon {
      width: 24px;
      height: 24px;
    }
    .infos-icon {
      width: 20px;
      margin-left: 7px;
    }
`
const LignesFlux: React.FC<LignesFluxProps> = ({type, src}) => {
  return (
    <CustomDiv className='row align-items-center pb-2'>
        <div className="col-1 text-start">
          <img src={src} alt='Lignes 225' className='ligne-icon'/>
        </div>
        <div className="col-11">
          <div className="row align-items-center flex-nowrap">
            {type === 'LINE_CC' ? <div className="col-auto">
                Ligne Courant Continu
            </div> : <div className="col-auto">{(LINE_LABELS as any)[type]}</div>}
          </div>
        </div>
    </CustomDiv>
  );
}

export default LignesFlux;