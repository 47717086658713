import React from 'react';
import Eye from "../../../assets/images/eye.svg";
import EyeClose from "../../../assets/images/eye-close.svg";
import styled from 'styled-components';
import TooltipTrigger from './TooltipTrigger';

interface ItemsTitleProps {
    active?: boolean;
    eye?: boolean;
    handleClick?: () => void;
    title: string;
}
const CustomDiv = styled.div`
  .title {
    font-size: 21px;
    font-weight: 600;
  }
  .text-help {
    position: relative;
    left: 8px;
    top: 2px;
  }
  
`
const ItemsTitle: React.FC<ItemsTitleProps> = ({handleClick, active, title, eye}) => {
  return (
    <CustomDiv className="row">
    <div onClick={handleClick} style={{cursor: !eye ? "pointer" : "auto"}} className="col-12">
        <div className="row pt-3 pb-2">
            <div className="col-10 fs-6">
              <div className="row">
                <div className='col-12 title'>{title} 
                  {(title === 'Consommation' || title === 'Production') && <TooltipTrigger description="Puissance moyennée"/>}
                  {title === 'Réseau' && <span className="text-help">*</span>}
                  </div>
                
              </div>
            </div>
            {!eye && <div className="col-2 text-end" style={{paddingRight: '30px'}}>
              <img src={active ? Eye : EyeClose} alt="Eye" />
            </div>}
        </div>
    </div>
    </CustomDiv>
  );
}

export default ItemsTitle;