import React from 'react';
import LignesFlux from './LignesFlux';
import { LINE_TYPES } from '../../../../utils/enums';
import Consommation from '../consommation';
import Ligne400 from '../../../../assets/images/lignes/ligne400.svg';
import Ligne225 from '../../../../assets/images/lignes/ligne225.svg';
import { useAppSelector } from '../../../../store/hooks';
import { selectCurrentRegion } from '../../../../store/mainReducer';

const Network = () => {
    const currentRegion = useAppSelector(selectCurrentRegion);
    return (
        <div className="row">
            <div className="col-12">
                <LignesFlux type={LINE_TYPES.LINE_400} src={Ligne400} />

            </div>
            {/*<div className="col-12">
                <LignesFlux type={LINE_TYPES.LINE_CC} src={ligneHVDC} />

            </div>*/}
            {currentRegion !== null && <div className="col-12">
                <LignesFlux type={LINE_TYPES.LINE_255} src={Ligne225} /></div>}
            <div className="col-12"><Consommation /></div>
            


        </div>
    );
}

export default Network;