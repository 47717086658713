import React, { useEffect } from 'react';
import ControlPanelComponent from './components/control-panel';
import MobileHeaderComponent from './components/shared/MobileHeaderComponent';
import ModalLegendComponent from './components/shared/ModalLegendComponent';
import MapComponent from './components/map';
import { SegmentFeature, SegmentProperties } from './models/SegmentFeature';
import { useAppDispatch } from './store/hooks';
import { setSegments, setRegions, setSites, setInitSegments, setSegmentTrips, setProductionUnits, setProductions, setNames } from './store/mainReducer';
import GeoJSON, { LineString, Polygon, Point } from 'geojson';
import { SiteFeature, SiteProperties } from './models/SiteFeature';
import Regions from "./data/REGIONS_V3_WGS84.json";
import Names from "./data/VILLES_V4.json";
import { LINE_VOLTAGE, LINE_COLORS } from './utils/enums';
import { ProductionUnitFeature, ProductionUnitProperties } from './models/ProductionUnitFeature';
import { BASE_URL } from './apiConfig';
import { Color } from '@deck.gl/core/typed';
import styled from 'styled-components';
const getLineWidth = (color: string): number => {
  switch(color) { 
    case LINE_VOLTAGE.LINE_255: {
      return 2;
    }
    case LINE_VOLTAGE.LINE_400: {
      return 3;
    }
    case LINE_VOLTAGE.LINE_HVDC: {
      return 3;
    }
  }
  return 3;
}
const getLineColor = (color: string): Color => {
  switch(color) { 
    case LINE_VOLTAGE.LINE_255: {
      return LINE_COLORS.LIT_225;
    }
    case LINE_VOLTAGE.LINE_400: {
      return LINE_COLORS.LIT_400;
    }
    case LINE_VOLTAGE.LINE_HVDC: {
      return LINE_COLORS.LIT_HVDC;
    }
  }
  return LINE_COLORS.LIT_HVDC;
}
function getSeuil(conso: number) {
  if(conso >= 450) {
    return 10;
  }
  if(conso < 450 && conso >= 340) {
    return 20;
  }
  return 30;
}

const CustomDiv = styled.div`
    position: absolute;
    bottom: 10px;
    right: 52px;
`
function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetch(`${BASE_URL}/segments`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const newData_National = data.features.map(
          (elt: GeoJSON.Feature<LineString, SegmentProperties>): SegmentFeature => {
            const voltage = elt.properties.Voltage === LINE_VOLTAGE.LINE_HVDC ? LINE_VOLTAGE.LINE_400 : elt.properties.Voltage;
            return {
              properties: {...elt.properties, Voltage: voltage},
              path: elt.geometry.coordinates,
              timestamps: elt.geometry.coordinates.length > 2 ? [0, 333, 666, 999] : [0, 999],
              color: getLineColor(voltage),
              lineWidth: getLineWidth(voltage),
            }
        });
        dispatch(setSegments(newData_National));
        dispatch(setInitSegments(newData_National));
        dispatch(setSegmentTrips(newData_National));
      })
      .catch(error => {
        console.error('Error:', error);
      });

    fetch(`${BASE_URL}/production_units`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const productionUnits = data.features.map(
          (elt: GeoJSON.Feature<Point, ProductionUnitProperties>): ProductionUnitFeature => {
            return {
              properties: {...elt.properties},
              position: elt.geometry.coordinates,
            }
        });
        dispatch(setProductionUnits(productionUnits));
      })
      .catch(error => {
        console.error('Error:', error);
      });

    fetch(`${BASE_URL}/sites`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const sites = data.features.map(
          (elt: GeoJSON.Feature<Polygon, SiteProperties>, index: number): SiteFeature => {
            return {
              properties: {...elt.properties},
              position: elt.geometry.coordinates,
              size: 1000,
              color: [253, 128, 93],
              visible: true,
              seuil: getSeuil(elt.properties.values[0])
            }
        });
        dispatch(setSites(sites));
      })
      .catch(error => {
        console.error('Error:', error);
      });
    
    fetch(`${BASE_URL}/production`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        dispatch(setProductions(data));
      })
      .catch(error => {
        console.error('Error:', error);
      });
      const regions = (Regions as any).features.map((feature: any) => {
          return {
              properties: feature.properties,
              coordinates: feature.geometry.coordinates,
              type: feature.geometry.type
          };
      });
      dispatch(setRegions(regions));
      
      const names = (Names as any).features.map((feature: any) => {
        return {
            position: feature.geometry.coordinates,
            NOM: feature.properties.NOM
        };
    });
    dispatch(setNames(names));
    

  }, [dispatch]);

  return (
    <div className='container-xxxl'>
      <div className="row vh-100-md p-10">
        <div className='col-12 d-block d-md-none'>
          <MobileHeaderComponent />
        </div>
        <div className="col-12 order-0 order-md-1 col-md-9 vh-md-100 vh-sm-100 pe-2 ps-2 pb-2 pt-md-2 shadow-sm custom-cf custom-cf-map">
          <MapComponent />
        </div>
        <div className="col-12 order-2 order-md-2 vh-100 col-md-3 d-none d-md-block custom-cf-panel">
          <ControlPanelComponent />
        </div>
        <CustomDiv className='col-12 d-block d-md-none'>
          <ModalLegendComponent />
        </CustomDiv>
      </div>
    </div>
  );
}

export default App;