import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';
import Infos from "../../../assets/images/infos.svg";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: #3D4D54;
    color: #FFFFFF;
    border-radius: 0;
  }
    .tooltip-arrow {
    border-top-color: #3D4D54;
  }

  .tooltip-arrow::before {
    border-top-color: #3D4D54;
    border-bottom-color: #3D4D54;
  }
`;

interface TooltipTriggerProps {
  description: string;
}
const TooltipTrigger: React.FC<TooltipTriggerProps> = ({
  description,
}) => {
  const renderTooltip = (props: any) => (
    <StyledTooltip id="button-tooltip" {...props}>
      {description}
    </StyledTooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={renderTooltip}
    >
      <span><img style={{marginLeft: "7px"}} src={Infos} alt="Infos" /></span>
    </OverlayTrigger>
  );
};

export default TooltipTrigger;
