import React from 'react';
import { useAppSelector } from '../../store/hooks';
import styled from 'styled-components';
import { selectCurrentRegion } from '../../store/mainReducer';
import LastUpdateTime from './legend/LastUpdateTime';
import { REGION_NUMBERS } from '../../utils/enums';
import Legend from './legend';

// 21323a
const CustomRow = styled.div`
  overflow: hidden;
  .parent {
    padding-top: 40px;
  }
  .panel-stack {
    height: 100vh;
    .child1, .child2 {
      flex: 0 0 auto;
    }
    .child3 {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: #21323A;
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: #3A5968; 
        border-radius: 8px;
      }
    }
    .text-infos {
    line-height: 17px;
    }
  }
`
const ControlPanelComponent = () => {
  const currentRegion = useAppSelector(selectCurrentRegion);
  return (
    <CustomRow className='row p-2 align-items-start vh-100' >
      <div className="col-12 vh-100 parent">
        <div className="row flex-nowrap flex-column panel-stack">
          <div className="col-12 child1">
            <LastUpdateTime />
          </div>
          <div className="col-12 text-uppercase mb-2 child2 custom-cf-title">
          {currentRegion === null ? 'National' : REGION_NUMBERS[currentRegion]}
          </div>
          <div className="col-12 child3 bg-sm">
            <div className="row">
              <div className="col-12"><Legend /></div>
              <div className="col-12 mt-4 ps-3 text-infos">
          * <span className="fs-7">Chaque ligne est représentée par un tracé symbolique entre ses extrémités, qui diffère du tracé réel.</span>
              </div>
            </div>
          
          </div>
          
        </div>
        
      </div>
      
      
    </CustomRow>
  );
};

export default ControlPanelComponent;
