import React from 'react';
import styled from 'styled-components';
import LastUpdateTime from '../control-panel/legend/LastUpdateTime';

const DivWrapper = styled.div`
  .site-title {
    weight: 700;
    font-size: 22px;
    line-height: 30px;
  }
`;
const MobileHeaderComponent = () => {

  return (
    <DivWrapper className="text-center p-2">
      <div className='row'>
      <div className="col-12">
      <LastUpdateTime />
      </div>
      </div>

      
    </DivWrapper>
  );
};

export default MobileHeaderComponent;
