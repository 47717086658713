import * as React from 'react';
import { SegmentFeature } from "../../models/SegmentFeature";
import CustomTripsLayer from "./CustomTripsLayer";
import { useAppSelector } from '../../store/hooks';
import { selectSegmentTripsV1 } from '../../store/mainReducer';

const useCustomTripsLayer = (): Array<CustomTripsLayer<SegmentFeature>> => {
  const visibleSegmentTrips = useAppSelector(selectSegmentTripsV1);
  const [time_s3, setTime_s3] = React.useState<number>(0);
  const [time_s4, setTime_s4] = React.useState<number>(0);
  const [time_s5, setTime_s5] = React.useState<number>(0);
  const loopLength = 1000;
  const animationSpeed_s3 = 3;
  const animationSpeed_s4 = 4.5;
  const animationSpeed_s5 = 6;
  const [animation] = React.useState<{id: number}>({id: 0});
  const animate = () => {
    setTime_s3(t => (t + animationSpeed_s3) % loopLength);
    setTime_s4(t => (t + animationSpeed_s4) % loopLength);
    setTime_s5(t => (t + animationSpeed_s5) % loopLength);
    animation.id = window.requestAnimationFrame(animate);
  };
  const trips_s3 = new CustomTripsLayer<SegmentFeature>({
    id: 'trips-3',
    data: visibleSegmentTrips.filter((elt: SegmentFeature) => elt.properties.ETAT === 3),
    widthUnits: 'pixels',
    getPath: d => d.path as any,
    getTimestamps: d => d.timestamps,
    getColor: d => [60, 179, 113],
    opacity: 0.5,
    getWidth: (d) => d.lineWidth,
    rounded: true,
    trailLength: 400,
    currentTime: time_s3,
    fadeTrail: true
  });
  const trips_s4 = new CustomTripsLayer<SegmentFeature>({
    id: 'trips-4',
    data: visibleSegmentTrips.filter((elt: SegmentFeature) => elt.properties.ETAT === 4),
    widthUnits: 'pixels',
    getPath: d => d.path as any,
    getTimestamps: d => d.timestamps,
    getColor: d => [60, 179, 113],
    opacity: 0.5,
    getWidth: (d) => d.lineWidth,
    rounded: true,
    trailLength: 400,
    currentTime: time_s4,
    fadeTrail: true
  });
  const trips_s5 = new CustomTripsLayer<SegmentFeature>({
    id: 'trips-5',
    widthUnits: 'pixels',
    data: visibleSegmentTrips.filter((elt: SegmentFeature) => elt.properties.ETAT === 5),
    getPath: d => d.path as any,
    getTimestamps: d => d.timestamps,
    getColor: d => [60, 179, 113],
    opacity: 0.5,
    getWidth: (d) => d.lineWidth,
    rounded: true,
    trailLength: 400,
    currentTime: time_s5,
    fadeTrail: true
  });
  React.useEffect(() => {
    animation.id = window.requestAnimationFrame(animate);
    return () => {
      window.cancelAnimationFrame((animation).id);
    };
  }, []);
  return [trips_s3, trips_s4, trips_s5];
};

export default useCustomTripsLayer;