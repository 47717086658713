import { useAppSelector } from "../../store/hooks";
import { ScatterplotLayer } from '@deck.gl/layers/typed';
import { SiteFeature } from '../../models/SiteFeature';
import { selectSites, selectToggleProductionVolume } from '../../store/mainReducer';
import { selectCurrentRegion } from '../../store/mainReducer';
const useScatterLayer = (): Array<ScatterplotLayer<SiteFeature>> => {
  const active = useAppSelector(selectToggleProductionVolume);
  const currentRegion = useAppSelector(selectCurrentRegion);
  const divider = currentRegion === null ? 1 : 2;
  // total --- 3746
  // dont abs > 11 --- 427
  const sites = useAppSelector(selectSites).filter((item) => {
    return item.properties.values[0] !== 0
  });
  const scatterplot225 = new ScatterplotLayer<SiteFeature>({
   id: 'scatterplot225-layer',
   data: sites,
   getPosition: d => d.position as any,
   getRadius: d => {
    return (Math.sqrt(Math.abs(d.properties.values[0])) * 4000) / divider;
  },
   stroked: true,
   visible: active,
   getFillColor: [37, 96, 125, 100],
    getLineColor: [37, 96, 125],
    getLineWidth: 1000,
 });

  return [scatterplot225];
};

export default useScatterLayer;