import type {NumericArray} from '@math.gl/core';
import {AccessorFunction, DefaultProps } from '@deck.gl/core/typed';
import {PathLayer, PathLayerProps} from '@deck.gl/layers/typed';
import {Texture2D} from '@luma.gl/webgl';
import arrowImage from '../../assets/images/icon-2.png';
const defaultProps: DefaultProps<CustomTripsLayerProps> = {
  fadeTrail: true,
  trailLength: {type: 'number', value: 120, min: 0},
  currentTime: {type: 'number', value: 0, min: 0},
  getTimestamps: {type: 'accessor', value: d => d.timestamps}
};

export type CustomTripsLayerProps<DataT = any> = _CustomTripsLayerProps<DataT> & PathLayerProps<DataT>;

type _CustomTripsLayerProps<DataT = any> = {
    fadeTrail?: boolean;
    trailLength?: number;
    currentTime?: number;
    getTimestamps?: AccessorFunction<DataT, NumericArray>;
};

export default class CustomTripsLayer<DataT = any, ExtraProps extends {} = {}> extends PathLayer<
  DataT,
  Required<_CustomTripsLayerProps<DataT>> & ExtraProps
> {
  static layerName = 'TripsLayer';
  static defaultProps = defaultProps;

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': `\
uniform float trailLength;
attribute float instanceTimestamps;
attribute float instanceNextTimestamps;
varying float vTime;
`,
      // Timestamp of the vertex
      'vs:#main-end': `\
vTime = instanceTimestamps + (instanceNextTimestamps - instanceTimestamps) * vPathPosition.y / vPathLength;
`,
      'fs:#decl': `\
uniform bool fadeTrail;
uniform float trailLength;
uniform float currentTime;
varying float vTime;
vec4 colorStart = vec4(255, 255, 255, 0); // #59ADCB
vec4 colorMid = vec4(255, 255, 255, 0.50); // #59ADCB
vec4 colorEnd = vec4(255, 255, 255, 1.0); // #59ADCB
vec4 mixedColor;
`,
      // Drop the segments outside of the time window
      'fs:#main-start': `\
if(vTime > currentTime || (fadeTrail && (vTime < currentTime - trailLength))) {
  discard;
}
float position = (vTime - (currentTime - trailLength)) / trailLength;
if (position <= 0.5) {
  mixedColor = mix(colorStart, colorMid, position * 2.0);
} else {
    mixedColor = mix(colorMid, colorEnd, (position - 0.5) * 2.0);
}
`,
      // Fade the color (currentTime - 100%, end of trail - 0%)
      'fs:DECKGL_FILTER_COLOR': `\
if(fadeTrail) {
  color = mixedColor;
  color.a *= 1.0 - (currentTime - vTime) / trailLength;
}
`
    };
    return shaders;
  }

  initializeState() {
    super.initializeState();
    const {gl} = this.context;
    const arrowTexture = new Texture2D(gl, {
        data: arrowImage,
        width: 114,
        height: 114,
        border: 15,
        parameters: {
          [gl.TEXTURE_MIN_FILTER]: gl.LINEAR_MIPMAP_LINEAR,
          [gl.TEXTURE_MAG_FILTER]: gl.LINEAR,
          [gl.TEXTURE_WRAP_S]: gl.CLAMP_TO_EDGE,
          [gl.TEXTURE_WRAP_T]: gl.CLAMP_TO_EDGE
        }
    });
    this.state = {
      ...this.state,
      arrowTexture
    } as any;
    const attributeManager = this.getAttributeManager();
    attributeManager!.addInstanced({
      timestamps: {
        size: 1,
        accessor: 'getTimestamps',
        shaderAttributes: {
          instanceTimestamps: {
            vertexOffset: 0
          },
          instanceNextTimestamps: {
            vertexOffset: 1
          }
        }
      }
    });
  }
  draw(params: any) {
    const {fadeTrail, trailLength, currentTime} = this.props;
    const {arrowTexture} = this.state as any;
    this.state.model.setUniforms({
      ...params.uniforms,
      arrowTexture: arrowTexture
    });
    params.uniforms = {
      ...params.uniforms,
      fadeTrail,
      trailLength,
      currentTime,
    };

    super.draw(params);
  }
}
