import { useAppSelector } from "../../store/hooks";
import { TextLayer } from '@deck.gl/layers/typed';
import { NameFeature } from '../../models/NameFeature';
import { selectCurrentRegion, selectNames } from '../../store/mainReducer';
const useNamesLayer = (): Array<TextLayer<NameFeature>> => {
  const names = useAppSelector(selectNames);
  const currentRegion = useAppSelector(selectCurrentRegion);
  const namesLayer = new TextLayer<NameFeature>({
    id: 'names-layer',
    data: names,
    getText: d => d.NOM,
    getPosition: d => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: [255, 255, 255],
    getBackgroundColor: [21, 38, 44, 160],
    backgroundPadding: [2, 2, 2, 2],
    getSize: 14,
    getTextAnchor: 'middle',
    pickable: true,
    // fontWeight: 'bold',
    background: true,
    getPixelOffset: [0, -25],
    characterSet: 'auto',
    outlineWidth: 3,
    outlineColor: [255, 255, 255],
    getBorderColor: [255, 255, 255],
    getBorderWidth: 0,
    visible: currentRegion !== null,
    fontFamily: 'Nunito Sans',
    /*
    fontSettings: {
      sdf: true,
      cutoff: 0.3,
      smoothing: 0.5
    },
    */
    /*
    // CollideExtension options
    collisionEnabled: true,
    getCollisionPriority: d => Math.log10(d.population),
    collisionTestProps: {
      sizeScale: fontSize * 2,
      sizeMaxPixels: sizeMaxPixels * 2,
      sizeMinPixels: sizeMinPixels * 2
    },
    extensions: [new CollisionFilterExtension()]
    */
 });

  return [namesLayer];
};

export default useNamesLayer;