import * as React from 'react';
import {DeckProps} from '@deck.gl/core/typed';
import {MapboxOverlay} from '@deck.gl/mapbox/typed';
import Map, {useControl, MapRef, MapLayerMouseEvent, LngLatBoundsLike} from 'react-map-gl';
import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import * as turf from '@turf/turf';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentRegion, selectRegions, setCurrentRegion } from '../../store/mainReducer';
import { Button } from 'react-bootstrap';
import InitZoom from "../../assets/images/init-zoom.svg";
import { RegionFeature } from '../../models/RegionFeature';
import MapStyle from "../../assets/map-styles/style.json";
import {fromJS} from 'immutable';
import usePathLayer from './usePathLayer';
import useCustomTripsLayer from './useCustomTripsLayer';
import useRegionsLayer from './useRegionsLayer';
import useHeatmapLayer from './useHeatmapLayer';
import useScatterLayer from './useScatterLayer';
// import useIconsLayer from './useIconsLayer';
import useFranceBorderLayer from './useFranceBorderLayer';
import useEuropeLayer from './useEuropeLayer';
import useWaterLayer from './useWaterLayer';
import useNamesLayer from './useNamesLayer';
import usePointLayer from './usePointLayer';
import useCurrentRegionsLayer from './useCurrentRegionsLayer';

function DeckGLOverlay(props: DeckProps) {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}
export default function MapComponent() {
  const franceBounds: LngLatBoundsLike = [
    [-4.821730434589966, 42.19881460595843],
    [8.933159896119463, 51.577932355176586] 
  ];
  
  const regions = useAppSelector(selectRegions);
  
  const mapRef = React.useRef<MapRef>() as React.Ref<MapRef>;
  
  const dispatch = useAppDispatch();
  const currentRegion = useAppSelector(selectCurrentRegion);
  const regionsLayer = useRegionsLayer();
  const franceBorder = useFranceBorderLayer();
  const currentRegionlayer = useCurrentRegionsLayer();
  
  const europeLayer = useEuropeLayer();
  const waterLayer = useWaterLayer();
  const pathLayer = usePathLayer();
  const customTrips = useCustomTripsLayer();
  const heatMap = useHeatmapLayer();
  const scatterLayers = useScatterLayer();
  const pointLayer = usePointLayer();
  const namesLayers = useNamesLayer();
  // const iconsLayers = useIconsLayer();
  const onClick = (e: MapLayerMouseEvent) => {
    const clickPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);

    const currentMapRef = (mapRef as any).current.getMap();
    
    regions.forEach(function(region: RegionFeature) {
      const feature = turf.polygon(region.coordinates);
      if (turf.booleanPointInPolygon(clickPoint, feature)) {
        console.log(region);
        const bbox = turf.bbox(feature);
        const bounds = [[bbox[0], bbox[1]], [bbox[2], bbox[3]]];
        currentMapRef.fitBounds(bounds as LngLatBoundsLike, {
          padding: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 50
          }, duration: 1000
        });
        dispatch(setCurrentRegion(region.properties.INSEE_REG));
      }
    });
  };
  const handleInitZoom = () => {
    const currentMapRef = (mapRef as any).current.getMap();
    if(currentRegion !== null) {
      if(currentMapRef) {
        currentMapRef.fitBounds(franceBounds as LngLatBoundsLike, {
          padding: {padding: 10}, duration: 1000
        });
        dispatch(setCurrentRegion(null));
      }
    }
    
  }
  return (
    <Map
      initialViewState={{longitude: 2.5, latitude: 46.5, bounds: franceBounds}}
      doubleClickZoom={false}
      scrollZoom={false}
      dragPan={false}
      dragRotate={false}
      mapStyle={fromJS(MapStyle) as any}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      ref={mapRef}
      onClick={onClick}
      touchPitch={false}
      touchZoomRotate={false}
    >
      <DeckGLOverlay layers={[[
        ...heatMap,
        ...waterLayer,
        ...europeLayer,
        ...regionsLayer,
        ...currentRegionlayer,
        ...franceBorder,
        ...scatterLayers,
        ...pathLayer,
        ...customTrips,
        
        // ...iconsLayers,
        ...pointLayer,
        ...namesLayers,
        ]]}  />
      {currentRegion !== null && <Button className='btn-cf position-absolute p-0 z-3' style={{top: '10px', left: '10px', width: 'auto'}} variant="secondary" onClick={handleInitZoom}>
        <img src={InitZoom} alt=''/>
      </Button>}
      {/*<HoursSwitch />*/}
    </Map>
  );
}
/*
function getTooltip(info: PickingInfo) {
  const d = info.object as DataT;
  return d && `${d.from.name} -- ${d.to.name}`;
}
*/