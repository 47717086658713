import { useAppSelector } from "../../store/hooks";
import { IconLayer } from '@deck.gl/layers/typed';
import { NameFeature } from '../../models/NameFeature';
import { selectCurrentRegion, selectNames } from '../../store/mainReducer';
import Union from "../../assets/images/Union.svg";
const usePointLayer = (): Array<any> => {
  const names = useAppSelector(selectNames);
  const currentRegion = useAppSelector(selectCurrentRegion);
  const layer = new IconLayer<NameFeature>({
    id: 'icon-layer-1',
    data: names,
    getIcon: (d: any) => ({ url: Union, width: 256, height: 256}),
    sizeMinPixels: 32,
    getPosition: (d: any) => {
      return d.position
    },
    getColor: [255, 255, 0],
    visible: currentRegion !== null,
    pickable: true
  });

  return [layer];
};

export default usePointLayer;