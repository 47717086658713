import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { selectCurrentRegion, selectRegions } from "../../store/mainReducer";
import { useAppSelector } from "../../store/hooks";
const useCurrentRegionsLayer = (): Array<GeoJsonLayer> => {
  const currentRegionId = useAppSelector(selectCurrentRegion);
  const currentRegion = useAppSelector(selectRegions).find((region) => region.properties.INSEE_REG === Number(currentRegionId));
  const currentRegionObject = {
    type : "FeatureCollection",
    name : "FRANCE_CONTOUR",
    features : [{
      type : "Feature",
      geometry : {
        type : "Polygon",
        coordinates : currentRegion ? currentRegion.coordinates : []
      },
      properties : currentRegion ? currentRegion.properties : {}
		}
	]
  };
  const currentRegionFrance = new GeoJsonLayer({
    id: `current-region`,
    data: currentRegionObject,
    stroked: true,
    filled: false,
    pickable: true,
    getLineColor: [1, 164, 215], // #00A6D9
    lineWidthMinPixels: 4
  });
  return [currentRegionFrance];
};

export default useCurrentRegionsLayer;