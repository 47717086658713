import { SegmentProperties } from "../../models/SegmentFeature";
import { PathLayer } from "@deck.gl/layers/typed";
import { useAppSelector } from "../../store/hooks";
import { selectSegments } from "../../store/mainReducer";
import { PathStyleExtension } from '@deck.gl/extensions/typed';

const usePathLayer = (): Array<PathLayer<SegmentProperties>> => {
  const visibleSegments = useAppSelector(selectSegments);
  const paths = new PathLayer({
    id: 'path',
    data: visibleSegments,
    widthUnits: 'pixels',
    capRounded: true,
    getPath: (d) => d.path,
    getWidth: (d) => d.lineWidth,
    getColor: (d) => d.color,
    getDashArray: () => [6, 4], // Set dash array for the dashed line pattern
    dashJustified: true, // Adjust the rendering of the dashes to align with path ends
    extensions: [new PathStyleExtension({ dash: false })],
  });

  return [paths];
};

export default usePathLayer;