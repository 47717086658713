import React from 'react';
import LegendValue from '../../../assets/images/LegendValue.svg';
const ProductionVolumeItems = () => {
  return (
    <div className='row'>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <img src={LegendValue} alt="LegendValue" />
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default ProductionVolumeItems;