import { useAppSelector } from "../../store/hooks";
import { SiteFeature } from '../../models/SiteFeature';
import { selectCurrentRegion, selectSites, selectToggleConsommation } from '../../store/mainReducer';
import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed';
import useDeviceDetect from "../shared/useDeviceDetect";
const useHeatmapLayer = (): Array<HeatmapLayer<SiteFeature>> => {
    const isMobile = useDeviceDetect();
    const visible = useAppSelector(selectToggleConsommation);
    const sites = useAppSelector(selectSites);
    const currentRegion = useAppSelector(selectCurrentRegion);
    const layer = new HeatmapLayer<SiteFeature>({
        id: 'HeatmapLayer',
        data: sites,
        aggregation: 'SUM',
        getPosition: (d: SiteFeature) => d.position as any,
	    getWeight : (d: SiteFeature) => d.properties.values[1],
        colorRange: [
            [34, 27, 54, 255],
            [58, 30, 73, 255],
            [82, 29, 92, 255],
            [106, 27, 95, 255],
            [128, 28, 91, 255],
        ],
        intensity: 20,
        radiusPixels: isMobile ? 5 : (currentRegion === null ? 40 : 60),
        threshold: 0.1,
        visible,
        pickable: false,
        weightsTextureSize: 512,
        debounceTimeout: 50000000,
        // colorDomain: [0, 600]
    });
    return [layer];
};

export default useHeatmapLayer;