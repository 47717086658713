import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectToggleProductionVolume, toggleProductionVolume } from '../../../store/mainReducer';
import ProductionVolumeItems from './ProductionVolumeItems';
import ItemsTitle from './ItemsTitle';

const ProductionVolume = () => {
  const dispatch = useAppDispatch();
  const active = useAppSelector(selectToggleProductionVolume);
  const handleClick = () => {
    dispatch(toggleProductionVolume());
  }
  return (
    <div className='row ps-3'>
      <div className="col-12">
        <ItemsTitle handleClick={handleClick} active={active} title='Production' />
      </div>
      <div className="col-12">
        <ProductionVolumeItems />
      </div>
    </div>
  );
}

export default ProductionVolume;