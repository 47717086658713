import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { SegmentFeature } from '../models/SegmentFeature';
import { RegionFeature } from '../models/RegionFeature';
import { SiteFeature } from '../models/SiteFeature';
import { NameFeature } from '../models/NameFeature';
import { LINE_VOLTAGE } from '../utils/enums';
import { createSelector } from 'reselect'
import { ProductionUnitFeature } from '../models/ProductionUnitFeature';
import { ProductionData, Productions } from '../models/Production';

interface ToggleState {
  lines: {
    animation: boolean;
  };
  consommation: boolean;
  productionVolume: boolean;
}

const initialToggleState: ToggleState = {
  lines: {
    animation: true
  },
  consommation: true,
  productionVolume: true,
};
interface MainState {
  currentRegion: string | null;
  segments: SegmentFeature[];
  initSegments: SegmentFeature[];
  segmentTrips: SegmentFeature[];
  regions: RegionFeature[];
  sites: SiteFeature[];
  names: NameFeature[];
  productionUnits: ProductionUnitFeature[];
  toggles: ToggleState;
  productions: Productions;
  currentProduction: ProductionData;
  currentHour: number;

}
const initialState: MainState = {
  currentRegion: null,
  segments: [],
  initSegments: [],
  segmentTrips: [],
  regions: [],
  sites: [],
  names: [],
  productionUnits: [],
  toggles: initialToggleState,
  productions: {
    horodate: '',
    data: {
      "00": {
        total: [{code: "", value: 0}],
        norenewable: [{code: "", value: 0}],
        renewable: [{code: "", value: 0}]
      }
    }
  },
  currentProduction: {
    total: [{code: "", value: 0}],
    norenewable: [{code: "", value: 0}],
    renewable: [{code: "", value: 0}]
  },
  currentHour: 0
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setCurrentRegion: (state, action) => {
      state.currentRegion = action.payload;
      for (const [key, value] of Object.entries(state.productions.data)) {
        if(Number(key) === Number(action.payload)) {
          state.currentProduction = value;
        }
      }
    },
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    setInitSegments: (state, action) => {
      state.initSegments = action.payload;
    },
    setSegmentTrips: (state, action) => {
      state.segmentTrips = action.payload;
    },
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setNames: (state, action) => {
      state.names = action.payload;
    },
    setProductionUnits: (state, action) => {
      state.productionUnits = action.payload;
    },
    setProductions: (state, action) => {
      state.productions = action.payload;
    },

    toggleLinesAnimation: (state) => {
      state.toggles.lines.animation = !state.toggles.lines.animation;
    },
    toggleConsommation: (state) => {
      state.toggles.consommation = !state.toggles.consommation;
    },
    toggleProductionVolume: (state) => {
      state.toggles.productionVolume = !state.toggles.productionVolume;
    },
    setCurrentHour: (state, action) => {
      state.currentHour = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentRegion, setSegments, setNames, setInitSegments,
  setSegmentTrips, setRegions, setSites, setProductionUnits, toggleLinesAnimation, toggleConsommation,
  toggleProductionVolume, setProductions, setCurrentHour
} = mainSlice.actions;
export const selectCurrentRegion = (state: RootState) => state.main.currentRegion;
export const selectSegments = (state: RootState) => {
  if(state.main.currentRegion) {
    return state.main.segments;
  }
  return state.main.segments.filter((item) => item.properties.Voltage === LINE_VOLTAGE.LINE_400 || item.properties.Voltage === LINE_VOLTAGE.LINE_HVDC);
};
export const selectToggleLinesAnimation = (state: RootState) => state.main.toggles.lines.animation;

export const selectSegmentTripsV1 = createSelector(
  [selectSegments, selectToggleLinesAnimation],
  (segments, lineAnimation) => {
    let result = [] as any;
    if(lineAnimation) {
      result = [...segments]
    }
    return result;
  }
);
export const selectRegions = (state: RootState) => state.main.regions;
export const selectSites = (state: RootState) => state.main.sites;
export const selectNames = (state: RootState) => state.main.names;
export const selectProductionUnits = (state: RootState) => state.main.productionUnits;
export const selectProductions = (state: RootState) => state.main.productions;
export const selectCurrentProduction = (state: RootState) => state.main.currentProduction;


export const selectToggleConsommation = (state: RootState) => state.main.toggles.consommation;
export const selectToggleProductionVolume = (state: RootState) => state.main.toggles.productionVolume;
export const selectCurrenHour = (state: RootState) => state.main.currentHour;

export default mainSlice.reducer;