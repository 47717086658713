import { GeoJsonLayer } from "@deck.gl/layers/typed";
import FRANCE_CONTOUR from "../../data/FRANCE_CONTOUR_WGS84_V1610.json";



const useFranceBorderLayer = (): Array<GeoJsonLayer> => {
  const franceContour = new GeoJsonLayer({
    id: `franceContour`,
    data: FRANCE_CONTOUR as any,
    stroked: true,
    filled: false,
    pickable: true,
    getLineColor: [1, 164, 215], // #00A6D9
    lineWidthMinPixels: 4
  });
  return [franceContour];
};

export default useFranceBorderLayer;