import React from 'react';
import { selectProductions, selectCurrenHour } from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
function formatDate(dateString: string, currentHour: number): string {
  const date = new Date(dateString);

  // Getting individual components
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  let year = date.getFullYear().toString();
  let hours = date.getHours().toString().padStart(2, '0');
  // const hours_tmp = currentHour * 8;
  let minutes = date.getMinutes().toString().padStart(2, '0');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // Assembling the formatted string
  return `${day}/${month}/${year} - ${hours}h${minutes}`;
}
const LastUpdateTime = () => {
  const productions = useAppSelector(selectProductions);
  const currentHour = useAppSelector(selectCurrenHour);

  
  return (
    <div className='row pbd-10px'>
        <div className="col-12" style={{height: '25px'}}>
        {productions.horodate !== '' && <div>
              <span className="fs-7">Dernière mise à jour</span> : {formatDate(productions.horodate, currentHour)}
            </div>}
        </div>
    </div>
  );
};

export default LastUpdateTime;